.flex-column {
  display: flex;
  flex-flow: column wrap;
  &.is-reverse {
    flex-direction: column-reverse;
  }
}

.flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  &.space-around {
    justify-content: space-around;
  }
  &.space-between {
    justify-content: space-between;
    &.start {
      align-items: flex-start;
    }
  }
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.flex-no-wrap {
  display: flex;
  flex-wrap: nowrap;
}

.center-horizontal {
  display: flex;
  justify-content: center;
}

.center-vertical {
  display: flex;
  align-items: center;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.sr-only {
  border: 0px;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0px;
  overflow: hidden;
  white-space: nowrap;
  position: absolute;
}

@import url('https://fonts.googleapis.com/css?family=Overpass+Mono:300');

.pattern-library {
  &__nav {
    text-align: right !important;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f1e8ff;
    margin: 20px 0 60px 0 !important;
  }
  &__nav-item {
    margin: 5px !important;
    padding: 0 14px;
    height: 40px;
  }
  &__components {
    margin: 30px 0 !important;
    text-align: left !important;
  }
  &__title,
  &__section-title,
  &__page-title {
    margin: 30px 0 !important;
  }
  &__section-title {
    margin-top: 60px !important;
  }
  &__columns {
    margin-top: 10px;
  }
  &__column {
    border: 1px solid var(--color-primary);
    border-radius: 16px;
  }
  &__icon {
    margin: 10px;
    &--light {
      margin: 10px;
      background-color: var(--color-primary);
      background-size: 70%;
      border-radius: 6px;
    }
  }
  &__code-block {
    background: white;
    border-radius: 3px;
    padding: 10px 16px;
    border: 1px solid #f1e7ff;
    width: 50%;
  }
  .code {
    font-size: 13px;
    color: #95919c;
    font-family: 'Overpass Mono', var(--font-default);
  }
  .btn {
    margin: 10px 20px 10px 0;
  }
}
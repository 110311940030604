/* issues with em dashes in colour variables, using hex in some places */
$white: #fff;
$lightgrey: #dbdbdb;
$grey: #95919c;
$black: #000;
$border: #e6e4eb;
$lightpurple: #9f69f0;

.datepicker-label {
  margin: 1rem;
}

.react-datepicker {
  width: 244px;
  border: 1px solid var(--color-grey-outline);
  font-family: var(--font-default);

  &-wrapper,
  &__input-container {
    width: 30rem;
  }

  &-popper {
    font-family: var(--font-default);
    &[data-placement^='bottom'] .react-datepicker__triangle {
      &::before {
        border-bottom-color: var(--color-grey-outline);
      }
      &::after {
        border-bottom-color: $white;
      }
    }
  }

  &__header {
    background: var(--color-primary-contrast);
    border-bottom: none;
  }

  &__month {
    margin-top: 5px;
  }

  &__current-month {
    font-family: var(--font-default);
    font-size: var(--font-size-medium);
    color: #40374f;
    font-weight: 400;
    margin-top: 4px;
  }

  &__day-names {
    color: $lightgrey;
    margin-top: 12px;
  }

  &__day-name,
  &__day {
    font-size: var(--font-size-medium);
    padding: 6px;
    width: 30px;
  }

  &__day-name {
    color: $lightgrey;
  }

  &__day {
    border-radius: 50%;
    &:hover {
      background: #f5e4fc;
      border-radius: 50%;
      color: $black;
    }
    &--selected,
    &--keyboard-selected {
      background: var(--color-primary);
      border-radius: 50%;
      &:hover {
        background: #f5e4fc;
        color: $black;
      }
    }
    &--disabled {
      color: $lightgrey;
    }
  }

  &__header__dropdown {
    margin: 20px 0;
  }

  &__navigation {
    &:hover,
    &:focus {
      *::before {
        border-color: var(--color-primary);
      }
    }
  }

  &__month-select,
  &__year-select {
    appearance: none;
    background: #f6f7f7;
    border: none;
    color: var(--color-grey-30);
    border-radius: 5px;
    padding-left: 15px;
    font-family: var(--font-default);
    font-size: 1.3rem;
    &:focus,
    &:hover {
      border: 1px solid var(--color-primary);
    }
  }

  &__mont-dropdown-container {
    width: 50%;
  }

  &__month-dropdown-container--select,
  &__year-dropdown-container--select {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      right: 15px;
      top: 16px;
      height: 5px;
      width: 5px;
      transform: rotate(-45deg);
      border: 2px solid var(--color-primary);
      border-top: none;
      border-right: none;
    }
  }
}

/* RESPONSIVE */

@mixin until $mobile {
  .react-datepicker {
    width: 90vw;
    &__header__dropdown {
      width: 89vw;
    }
    &__month-container {
      width: 100%;
    }
    &__month-dropdown-container {
      width: 90%;
      margin-bottom: 10px;
    }
    &__month-dropdown-container--select,
    &__year-dropdown-container--select,
    &__month,
    &__day-names {
      font-size: var(--font-size-medium);
    }
    &__month-dropdown-container--select,
    &__year-dropdown-container--select {
      width: 86%;
      &::after {
        height: 8px;
        width: 8px;
      }
    }
    &__month-select,
    &__year-select {
      width: 100%;
    }
    &__day-name,
    &__day {
      width: 13%;
      height: 13%;
      padding: 3.5%;
    }
  }
}

/* SECTION HEADERS */

.section__header {
  @mixin flex-row;
  justify-content: space-between;
  margin: 30px 0 40px 0;
  &__sub-container {
    @mixin padding-margin 0 10px, 0;
    @mixin flex-row;
    align-self: flex-end;
    align-items: flex-end;
    justify-content: flex-start;
  }
  &__title {
    line-height: 1;
    margin-right: 16px;
  }
  &__sub-title {
    font-size: var(--font-size-x-small);
    margin: 0 20px 0 0;
  }
  .btn.is-primary {
    margin: 0;
  }
}

/* RECORDS FILTER IN RECORDS HEADER */

.records-filter {
  &__list {
    @mixin padding-margin;
    list-style: none;
    align-items: center;
    justify-content: flex-start;
    margin: 12px 0 20px;
  }
  &__item,
  &__item:focus {    
    @mixin flex-row;
    @mixin padding-margin 3px 10px 3px 10px, 3px;
    text-transform: uppercase;
    font-size: var(--font-size-body);
    border-radius: 20px;
    cursor: pointer;
    align-items: center;
    outline: none;
    background: transparent;
    font-size: var(--font-size-xxx-small);
    transition: var(--transition);
    &:hover {
      /* border: 1px solid var(--color-app-background); */
      color: white;
    }
    .icon {
      @mixin padding-margin 0, 0 0 0 8px;;
    }
  }
  &__title {
    font-size: 1.3rem;
    margin-right: 10px;
    font-weight: 400;
    color: var(--color-grey-60);
    margin: 0 10px 0 0;
    align-self: center;
  }
}

.records-filter__dropdown {
  display: none;
}

.filters-container {
  display: 'flex';
  flex-direction: 'column';
  align-content: 'flex-start';
}

/* RESPONSIVE */

@mixin until 1408px {
  .providers .section__header__sub-container:last-child {
    width: 100%;
    justify-content: space-between;
  }
}

@mixin until $desktop {
  .requests__col-header.columns.is-gapless {
    padding: 0 30px;
  }
  .section__header__sub-container {
    margin-bottom: 20px;
  }
}

@mixin until $desktop {
  .section__header {
    margin-bottom: 20px;
    &__sub-container {
      padding: 0;
      width: 100%;
    }
    &__sub-title {
      margin: 18px 0 0 0;
    }
  }
}

@mixin until 700px {
  .records-filter__sidebar {
    display: none;
  }
  .records-filter__list {
    @mixin flex-row;
  }
  .records-filter__dropdown {
    display: block;
    margin-bottom: 20px;
    &__content {
      padding: 0 24px;
    }
  }
}



:root {
  $navbar-heading-gap: 60px;
  --navbar-heading-gap: 60px;
}

.page-header {
  padding-top: $navbar-heading-gap;
  padding-bottom: 120px;
  &.no-header-content {
    padding-top: 0;
    padding-bottom: 0;
  }
  &.is-purple {
    background: var(--color-primary);
    position: relative;
    /* .wave {
      @mixin wave;
      background-image: url(../images/wave-purple.svg)
    } */
    .type-h1 {
      color: white;
    }
    &.is-overlapped {
      padding-bottom: 200px;
    }
    &.fixed-height {
      height: 320px;
    }
  }
}

.page-container {
  .page-header {
    padding-top: 0;
  }
}

.signup,
.login {
  .is-subtitle {
    padding: 0;
  }
}
/* dropdown input */
select,
.select {
  text-align: left;
  display: inline-block;
  max-width: 100%;
  position: relative;
  vertical-align: top;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  &:not(.is-multiple) {
    &::after {
      @mixin icon;
      background-image: url('../icons/ic_keyboard_arrow_down_black_24px.svg');
      margin-top: -0.375em;
      right: 1.125em;
      top: 50%;
      z-index: 4;
    }
  }
  font-size: var(--font-size-x-small);
  max-width: 100%;
  outline: none;
  &::-ms-expand {
    display: none;
  }
  &[disabled]:hover {
    border-color: $input-hover-border-color;
  }
  &:not([multiple]) {
    padding-right: 2.5em;
  }
  &[multiple] {
    height: unset;
    padding: 0;
    option {
      padding: 0.5em 1em;
    }
  }
  /* States */
  &:hover {
    &::after {
      border-color: $input-hover-border-color;
    }
  }
}

.select-container {
  position: relative;
  margin-bottom: 5px;
  &::after {
    @mixin icon;
    position: absolute;
    background-image: url('../icons/ic_keyboard_arrow_down_black_24px.svg');
    margin-top: -0.375em;
    right: 10px;
    top: 21px;
    height: 24px;
    width: 24px;
    z-index: 4;
    pointer-events:none;
  }
}

.is-dropdown.has-error {
  border: 2px solid var(--color-error);
}

.is-dropdown--large {
  .input {
    height: 62px;
    padding-left: 22px;
  }
  &::after {
    position: absolute;
    content: '';
    top: 22px;
    right: 22px;
    height: 10px;
    width: 10px;
    transform: rotate(-45deg);
    border: 2px solid var(--color-grey-outline);
    border-top: none;
    border-right: none;
  }
  .type-paragraph.label-text {
    top: 18px;
    &.dropdown__label--small {
      top: 8px;
    }
  }
}

.dropdown__container {
  position: relative;
  .dropdown__label,
  .dropdown__label--small {
    z-index: 1;
    position: absolute;
    left: 24px;
    color: var(--color-grey-60);
    font-weight: 300;
  }
  .dropdown__label {
    top: -8px;
  }
  .dropdown__label--small {
    top: -18px;
    font-size: var(--font-size-small);
  }
  .input {
    padding: 12px 0 0 22px;
    appearance: none;
  }
}

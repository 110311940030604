.list {
  padding-top: 32px;
  padding-bottom: 32px;
}
.list-item {
  font-size: var(--body-text-size);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  list-style: none;
  padding: 1rem;
  background-color: white;
  margin-top: -1px;
  border: 1px solid #dbdbdb;
  .btn {
    &:last-child {
      margin-bottom: 0;
    }
  }

  &:first-of-type {
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
  }
  &:last-of-type {
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }
  &.is-selectable {
    &.is-selected {
      border: 1px solid var(--color-primary);
    }
     &:hover {
      cursor: pointer
    }
    &:hover, &:focus {
      border: 1px solid var(--color-primary);
    }
  }
  &.is-selected {
    background-color: grey;
  }
}

/* @TODO: @flannery: if we need to animate these, we'll need to use objects
  instead of background SVGs */
.illustration,
.container.is-centered .illustration {
  min-height: 200px;
  max-height: 400px;
  content: '';
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-bottom: 24px;
  &.is-small {
    height: 26px;
    min-height: 26px;
  }
}

.illo-welcome-desktop {
  background-image: url('../illustrations/welcome-desktop.svg');
}

.illo-billing-screen {
  background-image: url('../illustrations/person-on-computer.svg');
  height: 200px;
  background-repeat: no-repeat;
  width: 100%;
  background-position: center top;
  margin-bottom: 2rem;
}

@mixin until $mobile-large {
  .illo-welcome-desktop {
    background-image: url('../illustrations/welcome-desktop-small.svg');
  }
}

@mixin until $tablet {
  .user-profile__image {
    display: none;
  }
}

.illo-verify-email {
  background-image: url('../illustrations/verify-email.svg');
}
.illo-onboarding-needed {
  background-image: url('../illustrations/illo-man-waving.webp');
}
.illo-onboarding-final {
  background-image: url('../illustrations/illo-man-pushing-switch.webp');
}
.illo-checked-green {
  background-image: url('../illustrations/check-success.svg');
  display: flex;
  width: 5rem;
  height: 5rem;
  margin-bottom: 5px;
  min-height: auto;
  max-height: auto;
}

.illo-bike-rider {
  background-image: url('../illustrations/bike-rider.webp');
}

.illo-welcome-desktop,
.illo-onboarding-needed,
.illo-verify-email {
  margin-top: 60px;
}

.alert-message {
  vertical-align: middle;
  position: relative;
  img {
    position: absolute;
    height: 24px;
    width: 24px;
    top: 50%;
  }
  span {
    position: absolute;
    top: 50%;
    left: 32px;
  }
}
.banner, .app-banner {
  padding: 16px 0px;
  z-index: 500;
  background-color: var(--color-success);
  width: 100%;
  line-height: 1;
  &__container {
    @mixin flex-row;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1344px;
    margin: 0 auto;
    @mixin until 1408px {
      max-width: 1152px;
    }
    @mixin until 1216px {
      max-width: 960px;
    }
    @mixin until 1024px {
      max-width: 90%;
    }
  }
  &__body {
    max-width: 93%;
    padding-bottom: 0;
    margin-bottom: 0;
    line-height: 1.3;
    @mixin until 317px {
      max-width: 86%;
    }
  }
  &__title {
    color: white;
    font-weight: 600;
    line-height: 1;
    margin-right: 8px;
  }
  &__content {
    max-width: 100%;
    line-height: 1;
    color: white;
    margin: 0;
    padding: 0;
    padding-bottom: 3px;
    margin-right: 16px;
  }
  .icon {
    margin-bottom: -2px;
    margin-right: 10px;
    flex-grow: 0;
    flex-shrink: 0;
  }
  a {
    color: white;
  }
  &.success {
    background-color: var(--color-success);
  }
  &.warning {
    background-color: var(--color-warning);
  }
  &.error {
    background-color: var(--color-error);
  }
}

.message {
  border: 1px solid #E0E0E0;
  border-radius: var(--border-radius);
  background-color: white;
  display: block;
  padding: 16px;
  &.is-success {
    background-color: var(--color-success);
    border-color: var(--color-success);
  }
  &.is-error {
    background-color: var(--color-error);
    border-color: var(--color-error);
  }
  ul {
    list-style: none;
    padding-left: 32;
    position: relative;
    li {
      display: flex;
      align-items: center;
      text-align: left;
      &:not(:last-child) {
        margin-bottom: 32px;
      }
      img {
        position: absolute;
        left: 0;
      }
    }
  }
  margin-bottom: var(--group-padding-x);
}

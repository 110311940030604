button#custom-dropdown,
.custom-dropdown {
  border: 1px solid var(--color-grey-90);
  border-radius: 16px;
  background: white;
  &__toggle {
    position: relative;
    background: white;
    border: none;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    margin: 0;
    .btn {
      height: 0;
      &:hover {
        background: white;
      }
    }
    span {
      margin-top: -10px;
      width: 100%;
      text-align: left;
    }
  }
  .icon {
    order: 1;
  }
  .is-chevron {
    &--down::after {
      top: 14px;
      right: 24px;
    }
    &--up::after {
      top: 21px;
      right: 24px;
    }
  }
}

/* Overriding default button styles */

button#custom-dropdown__toggle {
  padding: 24px 0 10px 24px;
  &:hover {
    background: white;
    color: var(--color-grey-40);
  }
}

.dropdown {
  position: relative;
  vertical-align: top;
  &.is-active {
    display: block;
  }
}

.dropdown-menu {
  position: absolute;
  width: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  margin-top: -15px;
  padding-bottom: 16px;
}
.dropdown-content {
  width: 92%;
  border: 1px solid var(--color-grey-90);
  border-radius: 0 0 16px 16px;
  background: white;
  overflow: auto;
  max-height: 400px;

  &::-webkit-scrollbar{
    border-radius: 16px;
    width: 5px;
    background-color: none;
  }

  &::-webkit-scrollbar-thumb{
    border-radius: 16px;
    box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #D5D0CA;
    opacity: 0.5;
  }
}

.dropdown-item {
  display: block;
  font-size: var(--font-size-medium);
  line-height: 2;
  padding: 0.375rem var(--font-size-small);
  color: var(--color-grey-40);
  font-weight: 500;
  position: relative;
  &.selectable {
    margin-left: 8px;
    color: var(--color-grey-60);
    font-weight: 300;
    &:hover {
      background-color: var(--color-app-background);
      cursor: pointer;
      border-left: 2px solid var(--color-primary);
    }
    &-text {
      color: var(--color-grey-60);
      font-weight: 300;
    }
  }
  &.selected {
    background-color: var(--color-app-background);
    cursor: pointer;
    border-left: 2px solid var(--color-primary);
  }
  &--lists {
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding-inline-start: 2.4rem;
    & > li {
      color: var(--color-grey-40);
    }
  }
}

.dropdown-subitem {
  display: block;
  font-size: var(--font-size-medium);
  line-height: 2;
  color: var(--color-grey-60);
  font-weight: 300;
  position: relative;
}

.create-request-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: space-between;
  padding-right: 28px;
  .next-button {
    margin-left: 20px;
  }
  &__selected {
    margin: 20px;
    background-color: var(--color-purple-heart);
    .type-paragraph {
      color: white;
    }
  }
  & > * {
    margin: 5px 0;
  }
}
.create-request-form-grid {
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  margin: 2rem 0;
}

@media only screen and (max-width: 748px) {
  .create-request-form-grid {
    grid-template-columns: 1fr;
  }
}

.is-hidden-desktop {
  @mixin from $desktop {
    display: none;
  }
}
.section {
  padding: var(--section-padding-x) 0;
  -ms-overflow-style: none;
  &.is-tight {
    padding: 0;
  }
  .section-title {
    @mixin uppercase;
    padding-top: 8px;
    padding-bottom: 33px;
    color: #95919c;
    font-size: var(--font-size-small);
    margin: 0 auto;
    &.is-primary {
      color: var(--color-primary);
    }
  }
}

.scroll-container {
  overflow: scroll;
}

.section {
  &__content {
    @mixin flex-row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  &.overlaps-header {
    margin-top: -200px;
    padding-top: 50px;
    min-height: 200px;
  }
}

.app-container {
  min-height: 101vh;
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  background-color: var(--color-app-background);
}

.page-container {
  /* excludes the nav and the footer */
  flex: 1 1 0%; /* push footer down */
  padding-bottom: 1rem;
  &.is-tight {
    margin-top: -64px;
  }
}

.container {
  /* responsive content container */
  margin: 0 auto;
  position: relative;
  @mixin until $desktop {
    width: 90%;
  }
  @mixin from $desktop {
    width: calc(var(--desktop) - calc(2 * var(--gap)));
    &.is-fluid {
      padding-left: var(--gap);
      padding-right: var(--gap);
      width: 100%;
      box-sizing: border-box;
    }
  }
  @mixin until $widescreen {
    &.is-widescreen {
      max-width: calc(var(--widescreen) - calc(2 * var(--gap)));
      width: auto;
    }
  }
  @mixin until $fullhd {
    &.is-fullhd {
      max-width: calc(var(--fullhd) - calc(2 * var(--gap)));
      width: auto;
    }
  }
  @mixin from $widescreen {
    max-width: calc(var(--widescreen) - calc(2 * var(--gap)));
    width: calc(var(--widescreen) - calc(2 * var(--gap)));
  }
  @mixin from $fullhd {
    max-width: calc(var(--fullhd) - calc(2 * var(--gap)));
    width: calc(var(--fullhd) - calc(2 * var(--gap)));
  }
}

@mixin until 700px {
  .section__content {
    flex-direction: column;
  }
}

.element-group {
  display: block;
  padding-top: 16px;
  padding-bottom: 16px;
  &:last-child {
    margin-bottom: 0px;
  }
}

.page-section {
  padding-bottom: 32px;
  &:last-child {
    margin-bottom: 0px;
  }
}

.navbar-column {
  width: 20%;
}


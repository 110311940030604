:root {
  --map-height: 500px;
  --searchbox-height: 60px;
}

.map-with-list {
  .list {
    height: var(--map-height);
    padding: 0;
  }
}

.map-loading-element {
  height: 100%;
}

.map-container {
  height: 500px;
}

.google-map {
  height: 100%;
}

.map-marker-popup {
  max-width: 150px;
  max-height: 120px;
  padding: 16px;
  background-color: white;
  .popup-section {
    margin-top: 16px;
  }
}

.map-searchbox {
  font-family: var(--font-default);
  font-weight: 300;
  font-size: 16px;
  box-sizing: border-box;
  border: 1px solid transparent;
  width: calc(100% - 16px);
  height: 60px;
  margin: 8px;
  margin-right: 8px;
  padding: 0 12px;
  outline: none;
  @mixin box-shadow;
  @mixin ellipsis;
}


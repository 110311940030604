:root {
  /* svg file urls */
  --svg-loading: url('../icons/processing.svg');
  --svg-pending: url('../icons/pending.svg');
  --svg-arrow-right: url('../icons/arrow-right.svg');
  --svg-alert: url('../icons/ic_error_outline_purple_24px.svg');
  --svg-check: url('../icons/ic_check_purple.svg');
  --svg-shopping-card: url('../icons/shopping-cart.svg');
  --svg-eye-open: url('../icons/ic-eye-open.svg');
  --svg-eye-closed: url('../icons/ic-eye-closed.svg');

  /* sizes  */
  --icon-size-x-small: 8px;
  --icon-size-small: 16px;
  --icon-size-regular: 20px;
  --icon-size-medium: 24px;
  --icon-size-large: 32px;
  --icon-size-x-large: 50px;
  --icon-size-xx-large: 120px;
}

@define-mixin icon {
  display: inline-block;
  content: '';
  width: var(--icon-size-small);
  height: var(--icon-size-small);
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  &.is-block {
    display: block;
  }
  &.is-x-small {
    width: var(--icon-size-x-small);
    height: var(--icon-size-x-small);
  }
  &.is-regular {
    width: var(--icon-size-regular);
    height: var(--icon-size-regular);
  }
  &.is-medium {
    width: var(--icon-size-medium);
    height: var(--icon-size-medium);
  }
  &.is-large {
    width: var(--icon-size-large);
    height: var(--icon-size-large);
  }
  &.is-x-large {
    width: var(--icon-size-x-large);
    height: var(--icon-size-x-large);
  }
  &.is-xx-large {
    width: var(--icon-size-xx-large);
    height: var(--icon-size-xx-large);
  }
  &.ic-check {
    &-white {
      background-image: url('../icons/ic-check-white.svg');
    }
    &-filled {
      background-image: url('../icons/ic-check-filled.svg');
    }
    &-unfilled {
      background-image: url('../icons/ic-check-unfilled.svg');
    }
  }
  &.ic-followup-flag {
    background-image: url('../icons/ic-followup-flag.svg');
  }
  &.ic-help {
    background-image: url('../icons/ic-help.svg');
  }
  &.ic-allergies {
    background-image: url('../icons/ic-allergies.svg');
  }
  &.ic-appt {
    background-image: url('../icons/ic-appt.svg');
  }
  &.ic-history {
    background-image: url('../icons/ic-history.svg');
  }
  &.ic-intake {
    background-image: url('../icons/ic-intake.svg');
  }
  &.ic-er {
    background-image: url('../icons/ic-er.svg');
  }
  &.ic-patient {
    background-image: url('../icons/ic-patient.svg');
  }
  &.ic-refer {
    background-image: url('../icons/ic-refer.svg');
  }
  &.ic-check--green {
    background-image: url('../icons/ic-check--green.svg');
  }
  &.ic-rx {
    background-image: url('../icons/ic-prescription.svg');
  }
  &.ic-record {
    background-image: url('../icons/ic_card_general.svg');
  }
  &.ic-clinical {
    background-image: url('../icons/ic-clinical-notes.svg');
  }
  &.ic-imaging {
    background-image: url('../icons/ic-imaging.svg');
  }
  &.ic-lab {
    background-image: url('../icons/ic-lab-results.svg');
  }
  &.ic-img {
    background-image: url('../icons/ic-img.png');
  }
  &.ic-eye-closed {
    background-image: url('../icons/ic-eye-closed.svg');
  }
  &.ic-pdf {
    background-image: url('../icons/ic_pdf.svg');
  }
  &.ic-rotate {
    background-image: url('../icons/ic-rotate-white.svg');
    &.is-purple {
      background-image: url('../icons/ic-rotate-purple.svg');
    }
    &.is-white {
      background-image: url('../icons/ic-rotate-white.svg');
    }
  }
  &.ic-txt {
    background-image: url('../icons/ic-txt.png');
  }
  &.ic-steth {
    background-image: url('../icons/ic-steth.svg');
  }
  &.ic-canada {
    background-image: url('../icons/ic-canada.svg');
  }
  &.ic-lock-open {
    background-image: url('../icons/ic-lock-open-red.svg');
  }
  &.ic-lock-mid {
    background-image: url('../icons/ic-lock-open-yellow.svg');
  }
  &.ic-lock-closed {
    background-image: url('../icons/ic-lock-neutral.svg');
  }
  &.ic-lock-success {
    background-image: url('../icons/ic-lock-closed.svg');
  }
  &.ic-cal {
    background-image: url('../icons/ic-cal.svg');
  }
  &.ic-file {
    background-image: url('../icons/ic-file.svg');
    &-purple {
      background-image: url('../icons/ic-file-purple.svg');
    }
  }
  &.ic-pin {
    background-image: url('../icons/ic-map-pin.svg');
  }
  &.ic-pending {
    background-image: var(--svg-pending);
  }
  &.ic-face {
    background-image: url('../icons/ic-face.svg');
  }
  &.ic-noun-face {
    background-image: url('../icons/ic-noun-face.svg');
  }
  &.ic-rosetta-neutral {
    background-image: url('../icons/ic-rosetta-neutral.svg');
  }
  &.ic-camera-grey {
    background-image: url('../icons/ic-camera-grey.svg');
  }
  &.ic-camera--purple {
    background-image: url('../icons/ic-camera.svg');
  }
  &.ic-id {
    background-image: url('../icons/ic-id.svg');
  }
  &.ic-warning {
    background-image: url('../icons/ic-alert.svg');
    &-white {
      background-image: url('../icons/ic-warning-white.svg');
    }
    &-yellow {
      background-image: url('../icons/ic-warning-yellow.svg');
    }
    &-circle-red {
      background-image: url('../icons/ic-warning-circle-red.svg');
    }
    &-circle-white {
      background-image: url('../icons/ic-warning-circle-white.svg');
    }
  }
  &.ic-alert {
    background-image: url('../icons/triangle-warning.svg');
  }
  &.ic-error {
    background-image: url('../icons/ic-error-white.svg');
  }
  &.ic-location {
    background-image: url('../icons/my-location-purple.svg');
  }
  &.ic-check {
    background-image: url('../icons/ic_check_purple.svg');
  }
  &.ic-check-green {
    background-image: url('../icons/ic_check_green.svg');
  }
  &.ic-close {
    background-image: url('../icons/ic_add.svg');
    &-white {
      background-image: url('../icons/ic-close-white.svg');
    }
    &-filled {
      background-image: url('../icons/ic-close-filled.svg');
    }
    &-circle-white {
      background-image: url('../icons/ic-close-circle-white.svg');
    }
  }
  &.ic-close-gray {
    background-image: url('../icons/ic-close-gray.svg');
  }
  &.ic-close-red {
    background-image: url('../icons/ic-close-red.svg');
  }
  &.ic-ellipse {
    background-image: url('../icons/ic-ellipse.svg');
  }
  &.ic-eye-open {
    background-image: url('../icons/ic-eye-open.svg');
    &-white {
      background-image: url('../icons/ic_eye_open_white.svg');
    }
    &-orange {
      background-image: url('../icons/ic-eye-open-orange.svg');
    }
  }
  &.ic-eye-close {
    background-image: url('../icons/ic-eye-close.svg');
  }
  &.ic-mobile {
    background-image: url('../icons/ic_mobile.svg');
  }
  &.ic-desktop {
    background-image: url('../icons/ic_desktop.svg');
  }
  &.ic-camera {
    background-image: url('../icons/ic-camera-white.svg');
  }
  &.ic-id-shield {
    background-image: url('../icons/ic-id-shield.svg');
  }
  &.ic-shopping-cart {
    background-image: url('../icons/shopping-cart.svg');
  }
  &.ic-download {
    background-image: url('../icons/ic_file_download_white_24px.svg');
    &.is-purple {
      background-image: url('../icons/ic_file_download_purple_24px.svg');
    }
    &.is-white {
      background-image: url('../icons/ic_file_download_white_24px.svg');
    }
  }
  &.ic-arrow-forward {
    background-image: url('../icons/ic_arrow_forward_white_24px.svg');
    &.is-purple {
      background-image: url('../icons/ic_arrow_forward_purple_24px.svg');
    }
    &.is-white {
      background-image: url('../icons/ic_arrow_forward_white_24px.svg');
    }
  }
  &.ic-arrow-back {
    background-image: url('../icons/ic_arrow_back_white_24px.svg');
    &.is-purple {
      background-image: url('../icons/ic_arrow_back_purple_24px.svg');
    }
    &.is-white {
      background-image: url('../icons/ic_arrow_back_white_24px.svg');
    }
  }
  &.ic-arrow-down {
    background-image: url('../icons/ic_keyboard_arrow_down_black_24px.svg');
  }
  &.ic-zoom-in {
    background-image: url('../icons/ic_zoom_in_white_24px.svg');
    &.is-purple {
      background-image: url('../icons/ic_zoom_in_purple_24px.svg');
    }
    &.is-white {
      background-image: url('../icons/ic_zoom_in_white_24px.svg');
    }
  }
  &.ic-zoom-out {
    background-image: url('../icons/ic_zoom_out_white_24px.svg');
    &.is-purple {
      background-image: url('../icons/ic_zoom_out_purple_24px.svg');
    }
    &.is-white {
      background-image: url('../icons/ic_zoom_out_white_24px.svg');
    }
  }
  &.ic-search {
    background-image: url('../icons/ic_search_black_24px.svg');
    &-grey {
      background-image: url('../icons/ic_search_grey.svg');
    }
    &-primary {
      background-image: url('../icons/ic_search_primary.svg');
    }
  }
  &.ic-hello {
    background-image: url('../icons/ic-hand-wave.svg');
  }
  &.ic-profile-illo {
    background-image: url('../illustrations/illo-circle-profile.webp');
  }
  &.ic-star {
    background-image: url('../icons/ic-profile.svg');
    &-green {
      background-image: url('../icons/ic-profile-green.svg');
    }
  }
  &.ic-add {
    background-image: url('../icons/ic_add.svg');
    &-mint-circle-purple {
      background-image: url('../icons/ic_add-mint-circle-purple.svg');
    }
  }
  &.ic-profile-add {
    background-image: url('../icons/ic-profile-add.svg');
    &-mint {
      background-image: url('../icons/ic-profile-add-mint.svg');
    }
  }
  &.ic-building {
    background-image: url('../icons/ic-building.svg');
  }
  &.ic-green-lock {
    background-image: url('../icons/ic-green-lock.svg');
  }
  &.ic-membership-star {
    background-image: url('../icons/ic-membership-star.svg');
  }
  &.ic-green-arrow-share {
    background-image: url('../icons/ic-green-arrow-share.svg');
  }
  &.ic-checkbox-outline-grey {
    background-image: url('../icons/ic_checkbox_outline_grey.svg');
  }
  &.ic-checkbox-primary {
    background-image: url('../icons/ic_checkbox_primary.svg');
  }
  &.ic-download-cloud {
    background-image: url('../icons/ic_download_cloud.svg');
  }
  &.ic-arrow-forward-green {
    background-image: url('../icons/ic_arrow_forward_green.svg');
  }
  &.ic-check-circle-green {
    background-image: url('../icons/ic_check_circle_green.svg');
  }
  &.ic-circle-grey {
    background-image: url('../icons/ic_circle_grey.svg');
  }
  &.ic-radio-check-primary {
    background-image: url('../icons/ic_radio_check_primary.svg');
  }
  &.ic-sent-white {
    background-image: url('../icons/ic_sent_white.svg');
  }
  &.ic-processing-white {
    background-image: url('../icons/ic_processing_white.svg');
  }
  &.ic-check-circle-white {
    background-image: url('../icons/ic_check_circle_white.svg');
  }
  &.ic-dot-health-icon-circle {
    background-image: url('../icons/ic_dot_health_icon_circle.svg');
  }
  &.ic-sun-life-financial {
    background-image: url('../icons/sunlife_financial_logo.svg');
  }
  &.ic-ontario-health-west {
    background-image: url('../icons/ic_oh_west_logo.png');
  }
  &.ic-se-health {
    background-image: url('../icons/ic_sehc.png');
  }
  &.ic-green-shield-canada {
    background-image: url('../icons/ic_greenshield.png');
  }
  &.ic-canadalife {
    background-image: url('../icons/ic_canadalife.png');
  }
  &.ic-beneplan {
    background-image: url('../icons/ic_beneplan.png');
  }
  &.ic-bayshore {
    background-image: url('../icons/ic_bayshore.png');
  }
  &.ic-london-lambeth-medical-clinic {
    background-image: url('../icons/ic_london_lambeth.png');
  }
  &.ic-desjardins {
    background-image: url('../icons/ic_desjardins.png');
  }
  &.ic-edit-white {
    background-image: url('../icons/ic_edit_white.svg');
  }
  &.ic-berkeley-medical-clinic {
    background-image: url('../icons/ic_berkeley_medical_clinic.svg');
  }
  &.ic-edit-purple {
    background-image: url('../icons/ic_edit_purple.svg');
  }
  &.ic-lock-green {
    background-image: url('../icons/ic-lock-green.svg');
  }
  &.ic-copy {
    background-image: url('../icons/ic_copy.svg');
  }
  &.ic-sync {
    background-image: url('../icons/ic_sync_white.svg');
  }
  &.ic-union {
    background-image: url('../icons/ic_union.svg');
  }
  &.ic-caret-down {
    background-image: url('../icons/ic_caret_down.svg');
  }
  &.ic-caret-right {
    background-image: url('../icons/ic_caret_right.svg');
  }
  &.ic-processing-purple {
    background-image: url('../icons/ic-processing-purple.svg');
  }
  &.ic-sent-blue {
    background-image: url('../icons/ic-sent-blue.svg');
  }
  &.ic-check-purple {
    background-image: url('../icons/ic-check-purple.svg');
  }
  &.ic-slider-plus {
    background-image: url('../icons/ic-slider-plus.svg');
  }
  &.ic-slider-minus {
    background-image: url('../icons/ic-slider-minus.svg');
  }
  &.ic-success-snackbar {
    background-image: url('../icons/ic-success-snackbar.svg');
  }
  &.ic-progress-snackbar {
    background-image: url('../icons/ic-progress-snackbar.svg');
  }
  &.ic-error-snackbar {
    background-image: url('../icons/ic-error-snackbar.svg');
  },
  &.ic-enterprise {
    background-image: url('../icons/ic-enterprise.svg');
  },
  &.ic-log-out {
    background-image: url('../icons/ic-log-out.svg');
  },
  &.ic-bell {
    &-purple {
      background-image: url('../icons/ic-bell-purple.svg'); 
    }
  },
  &.ic-gear {
    &-grey {
      background-image: url('../icons/ic-gear-grey.svg');
    }
  },
  &.ic-archive {
    &-white {
      background-image: url('../icons/ic-archive-white.svg');
    }
    &-blue {
      background-image: url('../icons/ic-archive-blue.svg');
    }
    &-circle-white {
      background-image: url('../icons/ic-archive-circle-white.svg');
    }
    &-stroke-blue {
      background-image: url('../icons/ic-archive-stroke-blue.svg');
    }
  },
  &.ic-empty-avatar {
    background-image: url('../icons/ic-empty-avatar.svg');
  },
  &.ic-clock {
    &-white {
      background-image: url('../icons/ic-clock-white.svg');
    },
    &-stroke {
      background-image: url('../icons/ic-clock-stroke.svg');
    }
  },
  &.ic-checkmark-green-circle {
    background-image: url('../icons/circle-green-checkmark.svg');
  },
  &.ic-primary-triangle {
    background-image: url('../icons/triangle.svg');
  },
  &.ic-resource-green {
    background-image: url('../icons/ic-resource-green.svg');
  }
  &.ic-broken-image {
    background-image: url('../icons/ic_broken-image.svg');
  }
  @mixin-content;
}

.icon {
  @mixin icon;
}

.for-title {
  margin-bottom: 16px;
}

.text-with-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  .icon {
    margin-right: 8px;
  }
  .text {
    padding-bottom: 6px;
  }
  &.is-centered {
    justify-content: center;
  }
}

.viewer-container {
  overflow: scroll;
}
.document-viewer-container {
  height: 100vh;
  padding-top: 32px;
  padding-bottom: 72px;

}
.intercom-launcher-discovery {
  display: none;
}
.signature-viewer-container {
  height: 100vh;
}
.pdf-viewer, .signature-viewer {
  z-index: var(--z-index-modal);
  position: fixed;
  min-height: 100%;
  top: 0;
  right: 0;
  left: 0;
  background-color: var(--color-modal-background);

  header {
    font-size: var(--font-size-small);
    background-color: var(--color-primary);
    color: white;
    width: 100%;
    height: 100%;
    padding: 12px 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    * {
      padding: 0;
      margin: 0;
    }
  }
}

.pdf-controls {
  position: fixed;
  bottom: 100px;
  right: 32px;
  width: 100px;
  float: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .scale-control, .pagination-control {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
.pdf-pagination-button {
  width: 23px;
  height: 23px;
  border-radius: 8px;
  font-size: var(--font-size-large);
  line-height: 1;
  padding: 0;
  border-width: 2px;
}

/* hello sign */

.signature-viewer-mobile {
  position: fixed;
  min-height: 100%;
  top: 0;
  right: 0;
  left: 0;
}





@define-mixin card__records-radius {
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
}

.card__records {
  @mixin flex-row;
  @mixin padding-margin;
  justify-content: flex-start;
  height: fit-content;
  width: 80%;
  margin-left: 20px;
}

.card__records__single {
  @mixin padding-margin 0, 10px;
  position: relative;
  display: inline-block;
  flex: 1 1 calc(30% - 20px);
  min-width: 0;
  .card {
    display: block;
    width: 100%;
  }
  .card__split-header {
    @mixin card__records-radius;
    min-height: 84px;
    display: flex;
    align-items: flex-end;
  }
  .card__split-content {
    padding-top: 20px;
  }
  &__icon {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  &__title {
    color: white;
    font-size: var(--font-size-x-small);
    text-transform: none;
  }
  &__institution {
    text-transform: none;
  }
  &__types {
    overflow-y: hidden;
    white-space: nowrap;
    height: 20px;
    display: flex;
    align-items: center;
    &::-webkit-scrollbar {
      height: 0;
      background: transparent;
      display: none;
    }
  }
  &__types span {
    text-transform: uppercase;
    padding: 3px 8px 2px 8px;
    border-radius: 16px;
    margin-right: 5px;
    font-size: 1rem;
  }
  &__date {
    grid-row: 2;
    font-size: var(--desktop-font-size);
    padding: 0px 0px 14px 0px;
    text-transform: none;
  }
}

.card__checkbox {
  position: absolute;
  top: -10px;
  left: -10px;
  /* Hide the browser's default checkbox */
  input {
    display: none;
  }
  /* Custom checkbox */
  &-target {
    background-color:  var(--color-white);
    border: solid 4px var(--color-app-background);
    border-radius: 50%;
    box-shadow: inset 0px 0px 0px 2px var(--color-grey-60);
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 30px;
  }
  /* Add app background color under transparent "success" */
  &-label:hover .card__checkbox-target-container {
    background-color: var(--color-app-background);
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 30px;
  }
  /* On mouse-over, add a transparent "success" color */
  &-label:hover .card__checkbox-target {
    background-color: rgba(20, 228, 178, 0.6);
  }
  /* Style checkbox when box checked */
  input:checked ~ span .card__checkbox-target {
    background-color: var(--color-success);
    box-shadow: none;
  }
  /* Style checkmark when box checked */
  input:checked ~ span .card__checkbox-target:after{
    content: "";
    position: absolute;
    border: 2px solid var(--color-white);
    border-top: none;
    border-right: none;
    border-radius: 0% 10% 0% 10%;
    height: 6px;
    width: 13px;
    top: 5px;
    left: 4px;
    transform: rotate(-45deg);
  }
}

/* RESPONSIVE */

@mixin until $widescreen {
  .card__records__single {
    flex: 1 1 calc(50% - 20px);
  }
}
@mixin until 800px {
  .card__records__single {
    flex: 1 1 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@mixin until 700px {
  .card__records {
    width: 100%;
    margin: 0;
    &__single {
      flex: 1 1 calc(50% - 20px);
    }
  }
}

@mixin until 520px {
  .card__records__single {
    flex: 1 1 100%;
    margin: 10px 0;
  }
}

/* FIREFOX SPECIFIC FIX */

@-moz-document url-prefix() {
    .card__records__single__bottom {
        overflow: auto;
    }
}

_:-moz-tree-row(hover), .card__records__single__bottom {
  overflow: auto;
}
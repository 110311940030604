.container.is-fluid.page-container.is-centered.identity {
  padding-top: 0;
}

.onboarding__progress-bar {
  &__list {
    @mixin padding-margin;
    margin-bottom: 74px;
    width: 100%;
    @mixin flex-row;
    align-items: stretch;
    justify-content: space-between;
    list-style: none;
    position: relative;
  }
  &__list-item {
    width: 25%;
    height: 8px;
    background: #e9ebf3;
    &.complete {
      background: var(--color-turquoise);
      border-right: 3px solid var(--color-primary);
    }
  }
}
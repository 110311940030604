
.floater {
  border-radius: var(--border-radius);
  max-width: 300px;
  &__tooltip {
    background: #EBEEFB;
    padding: 16px 20px;
  }
  &__dictionary {
    padding: 20px 20px 12px 4px;
  }
  &__target {
    outline: none;
  }
  &__underlined-text {
    cursor: help;
    outline: none;
    padding: 0;
    border-bottom: 2px dotted #d2d4e1;
    background-color: transparent;
    transition: background-color ease-in 0.2s;
    &.is-active,
    &:focus,
    &:hover {
      border-bottom: 2px dotted #95919c;
    }
    &.is-dictionary-term {
      border-bottom: 1px solid transparent;
      background: #2effcd;
      border-radius: 16px;
      padding: 0 10px;
      margin: 1px 0;
    }
  }
  &__header {
    margin: 0 0 16px 0;
    font-size: var(--font-size-xx-small);
  }
  &__title {
    margin-bottom: 8px;
  }
  &__footer {
    color: #8285A4;
    font-style: italic;
    font-size: 1.2rem;
    padding-top: 20px;
  }
  &__close {
    float: right;
    cursor: pointer;
  }
}
.status {
  &__list {
    margin: -14px 0 0 0;
    padding: 0;
    list-style: none;
  }
  &__list-item {
    margin: 16px 0;
    i.icon.is-small {
      width: 20px;
      height: 20px;
    }
  }
  &__link-records {
    margin: 4px 0 4px 44px;
  }

}
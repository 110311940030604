.checkbox {
  margin: 24px 0;
  outline: none;
  &:hover, &:focus {
    .checkbox-target-container {
      border: 1px solid transparent;
      background: var(--color-primary);
      opacity: 0.4;
    }
  }
  &-label {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center
  }
  &-input {
    display: none;
  }
  &-text {
    margin-left: 20px;
    flex: 1
  }
}

{/* checkmark */}

.checkbox {
  &-target-container {
    width: 40px;
    height: 40px;
    border: 1px solid var(--color-primary);
    border-radius: 50%;
    position: relative;
    transition: all ease-in 0.05s;
  }
  input:checked + .checkbox-target-container {
    opacity: 1;
    background: var(--color-primary);
    /* checkmark */
    &:after {
      border: 2px solid var(--color-primary-contrast);
      border-top: none;
      border-right: none;
      content: "";
      height: 6px;
      width: 13px;
      top: 14px;
      left: 11px;
      position: absolute;
      transform: rotate(-45deg);
    }
  }
}
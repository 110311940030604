.accordion-list-header {
  transition: max-height 0.3s ease-out;
}

.accordion-list-header:hover {
  cursor: pointer;
}

.accordion-list-header-text {
  display: flex;
  align-items: center;
  padding: 1.4rem 0px;
  background: none;
  width: 100%;
}

.accordion {
  &-list-header-text.less-height {
    padding: 0.75rem 0px;
  }
  &-list-header.collapsed.less-height {
    padding: 0.75rem 0px;
  }
}

.accordion-list-heade-text.withPadding {
  padding: 0rem 2rem;
}

.is-expandable:hover,
.is-expandable:focus {
  outline: none;
  background: var(--color-catskill) !important;
}

.accordion-list-header.collapsed {
  overflow: hidden;
}

.accordion-list-content {
  cursor: default;
}
.accordion-list-content.withPadding {
  padding-top: 12px;
  padding-bottom: 12px;
  padding: 2rem;
}

@import './variables.css';

:root {
  --text-input-padding-x: 24px;
  --text-input-padding-y: 20px;

  --field-group-padding-x: 32px;
  --input-hover-color: var(--color-primary);
}

$input-border-color: #E0E0E0;
$input-hover-border-color: var(--color-primary);
$input-color: default;
$input-disabled-background-color: var(--color-grey-60);

.field {
  @mixin flex-column;
  .control {
    width: 100%;
    display: inline-block;
    position: relative;
  }
}

.input, .textarea {
  font-size: var(--font-size-body);
  width: 30rem;
  border: 2px solid var(--color-grey-80);
  color:var(--color-grey-40);
  margin: 5px 0px;
  outline: none;
  padding: 10px;
  border-radius: 3.6rem;
  background-color: white;
  &.has-error {
    border: 2px solid var(--color-error);
  }

  &.is-invalid {
    color:var(--color-placeholder-light);
  }

  &::placeholder {
    color:var(--color-placeholder-light);
  }

  &:focus {
    border: 2px solid var(--color-primary);
    background-color: var(--color-primary-contrast);
    caret-color: var(--color-primary);
  }
  &.is-expanded {
    width: 100%;
  }

  &[disabled] {
    background-color: var(--color-grey-100);
    color: var(--color-grey-60);
    box-shadow: none;
  }
}

input.is-disabled {
  background: none;
  border: 1px solid #E0E0E0;
  color: black;
}

.textarea {
  display: block;
  max-width: 100%;
  min-width: 100%;
  padding: 0.625em;
  resize: vertical;
  &:not([rows]) {
    max-height: 600px;
    min-height: 120px;
  }
  &[rows] {
    height: unset;
  }
}

.form-error {
  color: var(--color-error);
  padding-top: 16px;
}
.field-error {
  color: var(--color-error);
}

/* FILE INPUT
 * ============*/
.file-label {
  text-decoration: underline;
  cursor: pointer;
}
.file-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

/* TextInput / SearchInput */

.field-help-text {
  text-align: left;
  border-radius: var(--border-radius);
  background-color: var(--color-card-fill);
  vertical-align: text-top;
  min-height: 62px;
  box-sizing: border-box;
  padding: 16px 24px;
  color: #6F6A71;
  font-size: var(--font-size-small);
  position: relative;
  ul {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
  }
  &:after {
    position: absolute;
    left: 24px;
    top: -8px;
    content: ' ';
    width: 18px;
    height: 18px;
    background-color: var(--color-card-fill);
    transform: rotate(-45deg);
  }
  @mixin from $desktop {
    max-width: 250px;
    &:after {
      left: -8px;
      top: 24px;
    }
  }

}

/* react datepicker */
.react-datepicker-wrapper {
  width: 100%;
  .react-datepicker__input-container {
    width: 100%;
  }
}

.field-group.is-horizontal {
  display: flex;
  flex-direction: row;
  .radio-item {
    flex-grow: 1;
    flex-shrink: 1;
    &:not(:last-child) {
      margin-right: var(--element-padding-x);
    }
  }

}
/* TEXT INPUT< FLOating label */
.field {
  .control {
    width: 100%;
    margin-right: var(--element-margin-right);
    display: inline-block;
    position: relative;
    input, .input {
      width: 100%;
      font-weight: 300;
      /* keep this hidden */
      &::placeholder {
        opacity: 1;
        transition: all .2s;
      }
      &.has-icon-left {
        padding-left: 48px;
        .ic {
          position: absolute;
          left: 0;
        }
      }

      /* styling for label when input is blank or not focused: */
      &:placeholder-shown:not(:focus) + * {
        font-size: var(--font-size-medium);
        top: var(--text-input-padding-y);
        left: var(--text-input-padding-x);
        color: var(--color-grey-60);
      }
      &.has-icon-left {
        padding-left: 48px;
        position: relative;

      }
      &.has-icon-left + .icon {
        position: absolute;
        display: inline-block;
        top: 14px;
        left: 16px;
      }

    }
  }
}

.control.has-float-label {
  ::placeholder {
    color: transparent;
  }

  /* styling for 'floating' label (when input has text, or is focused) */

  label, > .label-text {
    display: flex;
    position: absolute;
    top: 10px;
    left: var(--text-input-padding-x);
    cursor: text;
    color: var(--color-grey-60);
    font-size: var(--font-size-small);
    transition: all .2s;
    display: inline-block;
    div {
      width: 100%;
    }
  }
  input {
    padding-top: 25px;
  }
  .icon {
    margin-right: 10px;
  }
}

.error-text {
  color: var(--color-error);
  font-size: 15px;
  margin: 8px 0px;
}

.text-input-right-icon {
  position: absolute;
  top: 16px;
  right: 12px;
  border: none;
  outline: none;
  .visibility-icon {
    background-color: transparent;
  }
}

/* Styling for label text for new datepicker component */
.label-text{
  color: #574F63;
  font-size: 1.5rem;
  font-weight: 300;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

@mixin until $mobile {
  .radio-input--horizontal.column {
    flex-flow: column wrap;
    .radio-item.radio-item--horizontal {
      border: 1px solid #E0E0E0;
      border-radius: var(--border-radius);
    }
  }
}

.control-relative {
  position: relative;
}

input.input.seach-field.has-icon-left {
  padding-top: 16px;
}

.create-provider {
  .btn.is-primary {
    margin-top: 30px;
  }
}
@keyframes spinner {
  to {transform: rotate(360deg);}
}

@define-mixin ellipsis $min-width: 0 {
  min-width: $min-width;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@define-mixin box-shadow {
  box-shadow: 0px 4px 11px rgba(18,6,35, 0.08);
  transition: box-shadow 0.3s cubic-bezier(.25,.8,.25,1);
}

@define-mixin box-shadow-hover {
  box-shadow: 0 14px 28px rgba(18,6,35,0.14), 0 10px 10px rgba(0,0,0,0.12);
}

@define-mixin spinner $color {
  width: 30px;
  height: 30px;
  display: block;
  border-radius: 50%;
  border: 0.25rem solid rgba(0, 0, 0, 0.20);
  border-top-color: $color;
  -webkit-animation: spinner 1s infinite linear;
  animation: spinner 1s infinite linear;
  @mixin-content;
}

.spinner {
  @mixin spinner var(--color-primary) {
    margin-top: 16px;
  }
  &.is-background {
    position: absolute;
    top: 200px;
    left: 50%;
    z-index: -1;
  }
}

@define-mixin has-icon-before {
  position: relative;
  padding-left: 24px;
  margin-left: -8px;
  &:before {
    content: "";
    @mixin-content;
    background-size: contain;
    background-repeat: no-repeat !important;
    position: absolute;
    left: var(--element-padding-x);
    top: 4.5px;
    width: var(--icon-size-small);
    height: var(--icon-size-small);
  }
}

@define-mixin disabled {
  cursor: not-allowed !important;
  background-color: transparent !important;
  border: 2px solid #E6E4EB !important;
  color: #E6E4EB !important;
  &:after {
    border-color: #E6E4EB !important;
  }
}

@define-mixin delete {
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: black;
  opacity: 0.2;
  border: none;
  border-radius: var(--border-radius-rounded);
  cursor: pointer;
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 0;
  height: 20px;
  max-height: 20px;
  max-width: 20px;
  min-height: 20px;
  min-width: 20px;
  outline: none;
  position: relative;
  vertical-align: top;
  width: 20px;
  &:before,
  &:after {
    background-color: white;
    content: "";
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    transform-origin: center center;
  }
  &:before {
    height: 2px;
    width: 50%;
  }
  &:after {
    height: 50%;
    width: 2px;
  }
  &:hover,
  &:focus {
    opacity: 0.3;
  }
  &:active {
    opacity: 0.4;
  }
  &.is-small {
    height: 16px;
    max-height: 16px;
    max-width: 16px;
    min-height: 16px;
    min-width: 16px;
    width: 16px;
  }
  &.is-medium {
    height: 24px;
    max-height: 24px;
    max-width: 24px;
    min-height: 24px;
    min-width: 24px;
    width: 24px;
  }
  &.is-large {
    height: 32px;
    max-height: 32px;
    max-width: 32px;
    min-height: 32px;
    min-width: 32px;
    width: 32px;
  }
}

.delete {
  @mixin delete;
}

@define-mixin flex-row {
  display: flex;
  flex-flow: row wrap;
}

@define-mixin flex-column {
  display: flex;
  flex-flow: column wrap;
}

@define-mixin padding-margin $padding: 0, $margin: 0 {
  padding: $padding;
  margin: $margin;
}

@define-mixin checkbox-circle {
  border-radius: 50%;
  width: 31px;
  height: 31px;
  border: 1px solid var(--color-grey-outline);
  margin-top: 2px;
  margin-left: 24px;
  margin-right: 24px;
  outline: none;
  &.is-selected {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    position: relative;
    /* checkmark */
    &:after {
      border: 2px solid var(--color-primary-contrast);
      border-top: none;
      border-right: none;
      content: "";
      height: 6px;
      width: 14px;
      top: 9px;
      left: 7px;
      position: absolute;
      transform: rotate(-45deg);
    }
  }
  @mixin-content;
}

@define-mixin uppercase {
  text-transform: uppercase;
}

@define-mixin card {
  min-width: 230px;
  display: flex;
  box-sizing: border-box;
  border-radius: var(--border-radius);
  background-color: #fff;
  /* box shadow */
  @mixin box-shadow;
}

@define-mixin wave {
  height: 100px;
  padding-bottom: 54px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 110%;
  width: 100%;
  position: absolute;
  bottom: -62px;
}
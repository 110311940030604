@define-mixin cardComponent {
  box-shadow: 0px 4px 11px rgba(18, 6, 35, .08);
  border-radius: var(--border-radius);
  background-color: white;
  padding: 17px 24px;
  margin-bottom: 20px;
  transition: box-shadow 0.3s cubic-bezier(.25,.8,.25,1);
  &:hover,
  &focus {
    box-shadow: 0 2px 28px rgba(9, 43, 62, .10), 0 10px 10px rgba(9, 43, 62, .08);
  };
  overflow: hidden;
}

@define-mixin planComponent {
  box-shadow: 0px 4px 11px rgba(18, 6, 35, .08);
  border-radius: 32px;
  background-color: white;
  padding: 17px 24px;
  margin-bottom: 20px;
  transition: box-shadow 0.3s cubic-bezier(.25,.8,.25,1);
  &:hover,
  &focus {
    box-shadow: 0 2px 28px rgba(9, 43, 62, .10), 0 10px 10px rgba(9, 43, 62, .08);
  }
}

.plan {
  @mixin planComponent;
  grid-column: 1 / span 2;
  display: grid;
}

/* base card styles and variants for Card component */
.card {
  @mixin cardComponent;
  &.no-shadow {
    box-shadow: none;
    border: 1px solid var(--color-grey-90);
    &:hover,
    &focus {
      box-shadow: none;
    }
  }
  &.no-padding {
    padding: 0;
  }
  &.no-radius {
    border-radius: 0;
  }
  &.no-border {
    border: none;
  }
  &.light-border {
    border: 1px solid var(--color-catskill);
  }
  &.is-selected {
    border: 2px solid var(--color-success);
    position: relative;
    left: -1px;
    border-radius: var(--border-radius) 18px var(--border-radius) var(--border-radius);
  }
  &.full-width {
    width: 100%
  }
}


.card,
.card__requests__single {
  border: 1px solid var(--color-catskill);
  width: auto;
  display: inline-block;
  padding: 24px;
  border-radius: var(--border-radius);
  margin-bottom: var(--group-padding-x);
  &.is-white {
    background-color: white;
  }
  &.is-grey {
    background-color: var(--color-grey-100);
  }
  &.is-block {
    display: block;
  }
  &.is-fixed-width {
    display: inline-block;
  }
}

.explainer-text {
  border-top: 1px solid #e6e4eb;
}

.explainer-block {
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-right: 24px;
  h4 {
    margin: 0;
  }
  &__spacer {
    &:after {
      content: '\B7';
      color: #999;
      font-size: 11px;
      padding: 8px;
    }
  }
}

.category-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.card__split {
  &-header {
    border-bottom: 1px solid var(--color-catskill);
    padding: 18px 24px;
  }
  &-content {
    padding: 24px;
  }
}
@define-mixin base-typography {
  padding: 0;
  margin: 0;
  font-weight: 300;
  color: var(--color-grey-40);
}

.small-caps-heading,
.is-primary,
a {
  color: var(--color-primary);
  text-decoration: none;
  font-size: var(--font-size-xxx-small);
  font-weight: 400;
  &:hover {
    color: var(--color-primary-light);
  }
  &.is-normal {
    color: inherit;
  }
  &.is-secondary {
    color: #95919c;
    &:hover {
      color: #666666;
    }
  }
  &.is-block {
    display: block;
  }
}

.is-tight-above {
  padding-top: 0;
}
.is-tight-below {
  padding-bottom: 0;
}

.type-h1,
.type-h2,
.type-h3,
.type-strong,
.type-chart__values,
.type-chart__label,
.type-tag,
.type-link,
.type-small,
.type-paragraph {
  text-align: left;
}

h1,
.type-h1 {
  @mixin base-typography;
  font-size: var(--font-size-h1);
  font-weight: 400; /* Keeping the font-weight for h1 to be 400 insted of 300 default as current app has more 400 weights than 300 */
  color: var(--color-header);
}

h2,
.type-h2 {
  @mixin base-typography;
  font-size: var(--font-size-h2);
  color: var(--color-header);
}

h3,
.type-h3,
.type-chart__title {
  @mixin base-typography;
  font-size: var(--font-size-h3);
  color: var(--color-grey-40);
}

h4,
.type-h4 {
  @mixin base-typography;
  font-size: var(--font-size-h4);
}

h5,
.type-h5 {
  @mixin base-typography;
  font-size: var(--font-size-h5);
}

h6,
.type-h6 {
  @mixin base-typography;
  font-size: var(--font-size-h6);
  color: var(--color-grey-40);
  font-weight: normal;
}

.type-h8 {
  @mixin base-typography;
  font-size: var(--font-size-link);
  color: var(--color-grey-40);
  line-height: 1.6;
  font-weight: normal;
}

p,
.type-paragraph {
  @mixin padding-margin 0, 0 0 6px 0;
  font-size: var(--font-size-body);
  font-weight: 300;
  line-height: 1.6;
  color: var(--color-header-secondary);
}

.type-small {
  @mixin padding-margin 0, 0 0 6px 0;
  font-size: var(--font-size-xx-small);
  font-weight: 300;
  line-height: 1.6;
  color: var(--color-header-secondary);
}

a,
.type-link {
  font-weight: 400;
  color: var(--color-primary);
  &:hover,
  &:focus {
    color: var(--color-lavender);
  }
}
.type-link {
  font-size: var(--font-size-link);
  cursor: pointer;
  font-size: inherit;
}

.type-chart {
  &__values {
    font-size: var(--font-size-xx-small);
    font-weight: 300;
    color: var(--color-grey-60);
  }
  &__label {
    font-size: var(--font-size-x-small);
    font-weight: 300;
    color: var(--color-grey-60);
  }
  &__title {
    margin: 0;
    font-weight: 400;
    font-size: var(--font-size-xx-small);
  }
}

.type-tag {
  font-size: var(--font-size-xxx-small);
  color: white;
}

$table-color: var(--color-grey-30);
$table-background-color: var(--color-primary-contrast);

$table-cell-border: 1px solid var(--color-grey-60);
$table-cell-border-width: 0 0 1px;
$table-cell-padding: 0.5em 0.75em;
$table-cell-heading-color: var(--color-grey-30);

$table-head-cell-border-width: 0 0 2px;
$table-head-cell-color: var(--color-grey-30);
$table-foot-cell-border-width: 2px 0 0;
$table-foot-cell-color: var(--color-grey-30);

$table-row-hover-background-color: grey;

$table-row-active-background-color: var(--color-primary-contrast);
$table-row-active-color: var(--color-primary);

$table-striped-row-even-background-color: var(--color-primary-contrast);
$table-striped-row-even-hover-background-color:  var(--color-primary-contrast);
$table-striped-row-bg-color: #f2f4fb;

.table {
  margin-bottom: 1.5rem;
  border-collapse: collapse;
  box-sizing: border-box;
  
  &-container {
    overflow: scroll;
  }

  td.is-left {
    text-align: left;
  }
  td,
  th {
    border-width: $table-cell-border-width;
    border-collapse: collapse;
    padding: $table-cell-padding;
    text-align: center;
    /* Modifiers */
    &.is-text {
      text-align: left;
    }
    &.is-narrow {
      white-space: nowrap;
      width: 1%;
    }
    &.is-selected {
      background-color: $table-row-active-background-color;
      color: $table-row-active-color;
      a,
      strong {
        color: currentColor;
      }
    }
  }
  th {
    background-color: var(--color-primary);
    color: var(--color-primary-contrast);
    border: 1px solid white;
    padding: 16px;
    &:first-of-type {
      border-top-left-radius: 6px;
    }
    &:last-of-type {
      border-top-right-radius: 6px;
    }
  }
  tr {
    &.is-selected {
      background-color: $table-row-active-background-color;
      color: $table-row-active-color;
      a,
      strong {
        color: currentColor;
      }
      td,
      th {
        border-color: $table-row-active-color;
        color: white;
      }
    }
    &.is-clickable {
      cursor: pointer;
      &:hover {
        box-sizing: border-box;
        border: 1px solid var(--color-primary);
      }
    }
  }
  thead {
    td,
    th {
      border-collapse: collapse;
      color: white;
    }
  }
  tfoot {
    td,
    th {
      border-width: $table-foot-cell-border-width;
      color: $table-foot-cell-color;
    }
  }
  tbody {
    tr {
      &:last-child {
        td,
        th {
          border-bottom-width: 0;
        }
      }
    }
  }
  /* Modifiers */
  &.is-bordered {
    td,
    th {
      border-width: 1px;
    }
    tr {
      &:last-child {
        td,
        th {
          border-bottom-width: 1px;
        }
      }
    }
  }
  &.is-fullwidth {
    width: 100%;
  }
  &.is-hoverable {
    tbody {
      tr:not(.is-selected) {
        &:hover {
          background-color: $table-row-hover-background-color;
        }
      }
    }
    &.is-striped {
      tbody {
        tr:not(.is-selected) {
          &:hover {
            background-color: $table-striped-row-even-hover-background-color;
          }
        }
      }
    }
  }
  &.is-narrow {
    td,
    th {
      padding: 0.25em 0.5em;
    }
  }
  &.is-striped {
    tbody {
      tr:not(.is-selected) {
        &:nth-child(even) {
          background-color: $table-striped-row-bg-color;
        }
      }
    }
  }
  &.is-dropdown {
    margin-top: -1px;
  }
}

tr {
  &.is-selected {
    background: rgba(46, 200, 255, 0.2) !important;
    border-bottom: 1px solid white;
  }
}

.login-partner__disclaimer {
  &__image {
    width: 200px;
    margin-bottom: 28px;
  }
  &__title {
    margin: 28px 0 8px 0;
  }
  &__legal {
    margin: 24px 0 28px 0;
  }
}
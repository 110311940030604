.pac-container {
  font-family: inherit;
  border: 1px solid var(--color-grey-outline);
  padding: 0 5px 5px 0;
  border-radius: 16px;
  background: white;
  margin-top: 1rem;
}

.pac-icon {
  display: none;
}

.pac-item {
  display: block;
  line-height: 2;
  padding: 0.8rem 1.2rem;
  font-weight: 500;
  position: relative;
  &-query {
    font-size: var(--font-size-medium);
    color: var(--color-header);
  }
  &-selected {
    background-color: var(--color-highlight);
    cursor: pointer;
    border-left: 2px solid var(--color-primary);
  }
  &:hover {
    background-color: var(--color-highlight);
    cursor: pointer;
    border-left: 2px solid var(--color-primary);
  }
}

:root {
  --navbar-height: 7.2rem;
  --navbar-padding: 1.6rem;

  @define-mixin dropDownbutton $color, $dimensions {
    cursor: pointer;
    display: block;
    position: relative;
    background-color: transparent;
    outline: none;
    padding: 0;
    border-radius: 50%;
    &:hover,
    &:focus {
      box-shadow: 0 0 0 2.5px var(--color-lavender)33
    }
    &.is-active {
      span {
        &:nth-child(1) {
          transform: translateY(10px) rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:nth-child(3) {
          transform: translateY(-10px) rotate(-45deg);
        }
      }
    }
  }
}

/* MAIN MENU -- desktop */
.navbar,
.navbar-menu,
.navbar-left,
.navbar-right {
  @mixin flex-row;
  gap: 2rem;
  list-style: none;
  margin: 0;
  padding: 0;
  z-index: var(--z-index-nav);
}

.navbar {
  background-color: var(--color-primary-contrast);
  border-bottom: 1px solid #f2f4fb;
  min-height: var(--navbar-height);
  color: var(--color-primary);
  .container {
    align-items: stretch;
    display: inline-flex;
    min-height: var(--navbar-height);
  }
  &-brand {
    align-items: center;
    display: flex;
    height: var(--navbar-height);
  }
  &-item:last-child {
    padding-right: 16px;
    margin-right: -16px;
  }
  &-menu {
    flex-grow: 1;
    flex-shrink: 0;
  }
  &-item {
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 1.5rem;
    font-weight: 300;
    text-transform: none;
  }
  &-link {
    cursor: pointer;
    font-weight: 400;
    font-size: var(--font-size-link);
    border-bottom: 2px solid transparent;
    &:hover:not(.logo),
    &:focus:not(.logo){
      color: var(--color-primary);
    }
    &.is-active {
      color: var(--color-primary);
      border-bottom: 2px solid var(--color-primary);
      cursor: default;
    }
  }
  &-item,
  &-link {
    @mixin flex-row;
    height: 100%;
    align-items: center;
    color: var(--color-grey-60);
    line-height: 1.5;
    padding: 1rem 1.5rem;
    position: relative;

    transition: 0.2s border-bottom ease-in;
    &:hover:not(.logo),
    &:focus:not(.logo),
    &.is-active {
      color: var(--color-primary);
    }
    &--no-icon {
      margin-left: 40px;
    }
    &--secondary {
      color: var(--color-grey-60);
    }
  }
  &-left {
    justify-content: flex-start;
    margin-right: auto;
  }
  &-right {
    justify-content: flex-end;
    margin-left: auto;
  }
}

/* logo */
.navbar .logo {
  width: 138px;
  height: 23px;
  background: no-repeat url('../wordmark.svg');
  background-size: cover;
  text-indent: -9999px;
  flex-shrink: 0;
  padding: 0;
  margin-right: 16px;
}

.navbar .partner-logo {
  display: flex;
  align-items: center;
  gap: 1rem;
  &-type {
    color: #40374F;
    font-weight: 400;
    font-size: var(--font-size-link);
  }
}

/* dropDownbutton-- desktop */

.navbar-dropdown-button {
  @mixin dropDownbutton var(--color-primary), var(--navbar-height);
  margin-left: auto;
  display: none;
}

/* SUBMENU -- desktop */
.navbar-link__sub-menu {
  position: relative;
  &:hover,
  &:focus {
    .navbar-link {
      background-color: var(--color-highlight);
      border-bottom: 2px solid var(--color-primary);
    }
    .navbar-link__sub-menu__list {
      display: block;
    }
  }
  &__list {
    display: none;
    position: absolute;
    margin: 0;
    padding: 0;
    list-style: none;
    width: 200px;
    background: white;
    z-index: var(--z-index-nav);
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    box-shadow: 0 2px 28px rgba(9, 43, 62, 0.1), 0 10px 10px rgba(9, 43, 62, 0.08);
    top: 72px;
    left: -100%;
  }
  &__link {
    display: inline-block;
    width: 100%;
    text-align: center;
    padding: 10px;
    color: var(--color-grey-60);
    background: white;
    transition: 0.2s background ease-in;
    &:hover,
    &:focus {
      background: var(--color-highlight);
    }
  }
  &__item:last-of-type .navbar-link__sub-menu__link {
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    padding-bottom: 14px;
  }
  &__item:first-of-type .navbar-link__sub-menu__link {
    padding-top: 16px;
  }
  &--profile {
    left: unset;
    right: 0;
  }
}

/* SUBMENU -- mobile */
@mixin touch {
  .navbar-link__sub-menu {
    &:hover .navbar-link {
      background-color: white;
      border-bottom: none;
    }
    &__list {
      margin: 0 0 10px 0;
      display: block;
      position: relative;
      top: 0;
      left: 0;
      box-shadow: none;
      width: 100%;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    &__link {
      text-align: left;
      padding-left: 30px;
      border-left: 2px solid transparent;
      &:hover,
      &:focus {
        border-left: 2px solid var(--color-primary);
      }
    }
    &__item:last-of-type .navbar-link__sub-menu__link,
    &__item:first-of-type .navbar-link__sub-menu__link {
      padding: 10px 10px 10px 30px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

/* MAIN MENU -- mobile */
@mixin touch {
  .navbar {
    &-dropdown-button {
      display: block;
    }
    .container {
      @mixin flex-column;
    }
    &-brand {
      width: 100%;
    }
    &-menu {
      display: none;
      &.is-active {
        display: block;
        margin-bottom: 24px;
      }
    }
    &-right,
    &-left {
      @mixin flex-column;
      margin: 0;
    }
    &-item,
    &-link {
      height: auto;
      border-left: 2px solid transparent;
      border-bottom: none;
      padding: 6px 14px;
      margin: 4px 0;
      &.is-active,
      &:focus:not(.logo),
      &:hover:not(.logo):not(.inactive) {
        border-left: 2px solid var(--color-primary);
        border-bottom: none;
        background: var(--color-highlight);
      }
    }
  }
}

.nav-dropdown {
  position: relative;
  z-index: 1;
  &__container {
    position: absolute;
    right: 0px;
    margin-top: 11px;
    width: 300px;
    border-radius: 5px;
    padding: 1rem;
    overflow: hidden;
    background: var(--color-white);
    border: 1px solid #ebeefb;
  }
  &__dropdown-button {
    display: block;
  }
}

/* PROVIDER CARDS
 * =================*/

.providers__card {
  width: 100%;
  &-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
  }
  &-content {
    display: grid;
    grid-template-columns: 1.25fr 2fr 1.5fr;
    grid-gap: 24px;
  }
  &-category-icon {
    margin: 0 20px 0 0;
  }
}

.providers__education {
  margin: -24px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  background: var(--color-catskill-white);
}

.help-yourself,
.patient-education {
  width: 100%;
  margin-bottom: 0;
  padding-bottom: 0;
  background: var(--color-catskill-white);
  &__title {
    margin: 0 0 12px;
  }
  &__subtitle {
    margin: 0 0 12px;
    display: block;
  }
  &__content {
    margin: 0 0 16px;
  }
  &__link {
    text-transform: none;
    font-size: 1.6rem;
  }
}

@mixin until $desktop {
  .providers__card-content {
    grid-template-columns: 1.25fr 2fr 2fr;
  }  
}

@mixin until 722px {
  .providers .card__split-content {
    padding: 0;
  }
  .providers__card-content {
    grid-template-columns: 1fr;
    margin: 0;
    padding: 28px 12px 24px;
    border-bottom: 1px solid #e9e8ee;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border-bottom: none;
    }
  }
  .providers__address,
  .providers__education {
    order: -1;
  }
  .providers__education {
    margin: 0 -36px;
    border-radius: 0;
    padding: 12px;
  }
}
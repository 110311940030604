.dashboard,
.providers,
.request {
  padding-bottom: 100px;
}

.record-share__verify,
.record-share--not-verified,
.password-reset,
.request {
  padding-top: 60px;
}

.enable-tfa {
  display: flex;
  justify-content: center;
}

.tfa-form {
  text-align: left;
  margin-left: 20px;
}

.tfa-form p {
  text-align: left;
  margin-bottom: 12px;
}

.tfa-form .input {
  margin-bottom: 20px;
}
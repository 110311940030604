:root {
  $tablet: var(--tablet);
  $mobile: var(--mobile);
  $desktop: var(--desktop);
  $widescreen: var(--widescreen);
  $fullhd: var(--fullhd);
}
@define-mixin touch {
  @media screen and (max-width: $desktop) {
    @mixin-content;
  }
}
@define-mixin desktop {
  @media screen and (min-width: $desktop) {
    @mixin-content
  }
}

@define-mixin tablet {
  @media screen and (min-width: $tablet), print {
    @mixin-content
  }
}

@define-mixin from $device {
  @media screen and (min-width: $device) {
    @mixin-content
  }
}

@define-mixin until $device {
  @media screen and (max-width: $device) { /* TODO: should be calc($device - 1px), but calc() not evaluating */
    @mixin-content
  }
}

@define-mixin mobile {
  @media screen and (max-width: $tablet) {
/* TODO: should be calc($tablet - 1px), but calc() not evaluating */

    @mixin-content
  }
}
@define-mixin desktop-only {
  @media screen and (min-width: $desktop) and (max-width: calc($widescreen - 1px)) {
    @mixin-content
  }
}

@define-mixin widescreen {
  @media screen and (min-width: $widescreen) {
    @mixin-content
  }
}

@define-mixin widescreen-only {
  @media screen and (min-width: $widescreen) and (max-width: calc($widescreen - 1px)) {
    @mixin-content
  }
}
